import React from 'react';
import * as S from './PlayPauseButton.styles';
import { Image } from '@chakra-ui/react';
import play from './play.png';
import pause from './pause.png';

interface IPlayPauseButton {
  active: boolean;
  callback: () => void;
}

const PlayPauseButton = ({ active, callback }: IPlayPauseButton) => {
  return (
    <S.IconButtonPlayPause
      alignSelf={'center'}
      variant={'secondary'}
      size="sm"
      width={'36px'}
      minWidth={'36px'}
      height={'36px'}
      aria-label={active ? 'pause-button' : 'play-button'}
      icon={
        <>
          {!active && <Image src={play} />}
          {active && <Image src={pause} />}
        </>
      }
      onClick={() => callback()}
    />
  );
};

export default PlayPauseButton;
