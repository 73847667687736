import { Box } from '@material-ui/core'
import { useDpr } from 'common/hooks/useDpr'
import React from 'react'
import { ReactComponent as Blob } from './blob.svg'
import { ReactComponent as BlobLeft } from './blobLeft.svg'

export interface FeatureListImageProps {
    image?: string
    variant?: 'right' | 'left'
}

export const FeatureListImage: React.FC<FeatureListImageProps> = ({
    image,
    variant = 'right'
}) => {
    const dpr = useDpr()
    return (
        <Box position='relative'>
            <img
                src={`${image}?auto=format&w=552&dpr=${dpr}`}
                alt='Feature List'
                style={{ position: 'relative', zIndex: 3 }}
                width='100%'
            />
            {variant === 'right' ? (
                <Blob
                    fill='black'
                    style={{
                        width: '127.44%',
                        height: '129.37%',
                        position: 'absolute',
                        left: '-10.83%',
                        top: '-14.29%',
                        zIndex: 2
                    }}
                />
            ) : (
                <BlobLeft
                    fill='black'
                    style={{
                        width: '127.36%',
                        height: '129.37%',
                        position: 'absolute',
                        left: '-14.31%',
                        top: '-14.68%',
                        zIndex: 2
                    }}
                />
            )}
        </Box>
    )
}
