import { Box, BoxProps, Typography, TypographyProps } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import React from 'react'
import Interweave from 'interweave'
import Tick from './Tick_Outline_Simple.svg'
export interface RichTextProps extends TypographyProps {
    box?: BoxProps
    html?: boolean
    label: string
}

const Output = styled(Box)({
    '& a': {
        cursor: 'pointer',
        color: '#3681D9',
        textDecoration: 'underline',
    },
    '& ul': {
        padding: 0,
        listStyleType: 'none',
        marginTop: '20px',
    },
    '& li': {
        color: '#766F90',
        fontSize: '14px',
        lineHeight: '20px',
        paddingBottom: 20,
        paddingLeft: 40,
        position: 'relative',
        '&::before': {
            content: '""',
            backgroundImage: `url(${Tick})`,
            display: 'block',
            height: 24,
            width: 24,
            position: 'absolute',
            left: '0',
        },
    },
    '& h6': {
        color: 'var(--Brand-900, #0C1E68)',
        fontFamily: 'Assistant',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '120%',
    },
})

export const RichText: React.FC<RichTextProps> = ({
    box,
    html,
    label,
    ...props
}) => {
    return (
        <Box {...box}>
            <Output>
                <Typography
                    {...{ ...props, component: html ? 'div' : undefined }}
                >
                    {html ? <Interweave content={label} /> : label}
                </Typography>
            </Output>
        </Box>
    )
}
