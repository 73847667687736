import React from 'react';
import { JumbotronInfo } from '../JumbotronCarrouselHomePage';
import { Box, Image } from '@chakra-ui/react';
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text';
import { BulletProps } from '../../../templateSections/BulletsWithImage/Bullet';

const Bullets = ({ jumbotronDetails }: { jumbotronDetails: JumbotronInfo }) => {
  return (
    <>
      {jumbotronDetails.bullets && jumbotronDetails.bullets.length > 0 && (
        <Box display="flex" flexDirection="column" gap="4px">
          {jumbotronDetails.bullets.map(
            (bullet: BulletProps, index: number) => (
              <Box display="flex" gap="6px" key={`bullet-${index}`}>
                {bullet.icon && (
                  <Box
                    display="flex"
                    width="24px"
                    height="24px"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Image src={bullet.icon} />
                  </Box>
                )}
                {bullet.title && (
                  <Text variant="body-md" color="#ffffff">
                    {bullet.title}
                  </Text>
                )}
              </Box>
            )
          )}
        </Box>
      )}
    </>
  );
};

export { Bullets };
