import React from 'react';
import { JumbotronInfo } from '../JumbotronCarrouselHomePage';
import { Box, Image } from '@chakra-ui/react';

const TopIcon = ({ jumbotronDetails }: { jumbotronDetails: JumbotronInfo }) => {
  return (
    <>
      {jumbotronDetails.top_icon && (
        <Box display={['none', 'none', 'none', 'block']}>
          <Image src={jumbotronDetails.top_icon} />
        </Box>
      )}
    </>
  );
};

export { TopIcon };
