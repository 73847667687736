import React from 'react';
import styled from 'styled-components'
import Tick from './Tick_Outline_Simple.svg'
import { Box } from '@chakra-ui/react';

interface Props {
    children: React.ReactNode;
}

const StyledComponent = styled(Box)`
    & ul {
        padding: 0;
        list-style-type: none;
    }
    
    & li {
        position: relative;
        padding-left: 40px;
        margin-bottom: 10px;
    
        &:before {
            content: "";
            background-image: url(${Tick});
            display: block;
            height: 24px;
            width: 24px;
            position: absolute;
            left: 0;
        }
    }
    `;

const StyledBulletPoints = ({ children }: Props) => {
    return <StyledComponent>
        { children }
    </StyledComponent>;
}

export { StyledBulletPoints };