import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import { BulletProps } from '../../templateSections/BulletsWithImage/Bullet';
import { Awards } from './components/Awards';
import { AppsButtonsAndHyperlink } from './components/AppsButtonsAndHyperlink';
import { CTAs } from './components/CTAs';
import { Bullets } from './components/Bullets';
import { BodyOfText } from './components/BodyOfText';
import { Title } from './components/Title';
import { TopIcon } from './components/TopIcon';
import { Subtitle } from './components/Subtitle';
export interface IJumbotronsHomeWithCarrousel {
  jumbotronDetails: JumbotronInfo;
  jumbotronIndex: number;
  stepper: JSX.Element;
}

export interface JumbotronInfo {
  top_icon?: string;
  title?: string;
  subtitle?: string;
  bullets?: BulletProps[];
  body_of_text?: string;
  image: string;
  app_button_1___image?: string;
  app_button_1___url?: string;
  app_button_2___image?: string;
  app_button_2___url?: string;
  hyperlink___text?: string;
  hyperlink___url?: string;
  awards_title?: string;
  award_image___1?: string;
  award_image___2?: string;
  award_image___3?: string;
  awards_hide_on_mobile?: 'Yes' | 'No';
  analyticseventname_primary?: string;
  analyticseventname_secondary?: string;
}

const mobileClipPath = 'polygon(0 0, 100% 0, 100% 100%, 0% 66%)';
const desktopClipPath = 'polygon(12% 0, 100% 0, 100% 100%, 0% 100%)';

const JumbotronCarrouselHomePage = ({
  stepper,
  jumbotronDetails,
  jumbotronIndex,
}: IJumbotronsHomeWithCarrousel) => {
  const image = jumbotronDetails.image;

  const LeftColumn = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        paddingTop={['0px', '0px', '55px', '55px']}
        paddingLeft={['32px', '32px', '10px', '10px']}
        paddingRight={['32px', '32px', '10px', '10px']}
      >
        <Box display="flex" flexDirection="column" maxWidth="472px" gap="32px">
          <Box display="flex" flexDirection="column" gap="24px">
            <Box display="flex" flexDirection="column" gap="16px">
              <Subtitle jumbotronDetails={jumbotronDetails} />

              <TopIcon jumbotronDetails={jumbotronDetails} />

              <Title jumbotronDetails={jumbotronDetails} />
            </Box>

            <BodyOfText jumbotronDetails={jumbotronDetails} />

            <Bullets jumbotronDetails={jumbotronDetails} />

            <Box display="flex" flexDirection="column" gap="32px">
              <CTAs
                jumbotronDetails={jumbotronDetails}
                jumbotronIndex={jumbotronIndex}
              />

              <AppsButtonsAndHyperlink jumbotronDetails={jumbotronDetails} />

              <Awards jumbotronDetails={jumbotronDetails} />
            </Box>
          </Box>
          <Box display="flex">{stepper}</Box>
        </Box>
      </Box>
    );
  };

  const ImageColumn = () => {
    return (
      <Image
        width="100%"
        sx={{
          aspectRatio: '16/9',
        }}
        src={image}
        alt="Hero"
        srcSet={`
          ${image}?auto=format&w=150 150w,
          ${image}?auto=format&w=300 300w,
          ${image}?auto=format&w=600 600w,
          ${image}?auto=format&w=1200 1200w,
          ${image}?auto=format&w=2400 2400w,
          `}
        sizes="(max-width: 767px) 100vw, 50vw"
        objectFit="cover"
        height="100%"
        display="flex"
        overflowY="hidden"
        clipPath={[
          mobileClipPath,
          mobileClipPath,
          mobileClipPath,
          desktopClipPath,
        ]}
      />
    );
  };

  return (
    <>
      <BaseLayout leftColumn={LeftColumn()} imageColumn={ImageColumn()} />
    </>
  );
};

const BaseLayout = ({
  leftColumn,
  imageColumn,
}: {
  leftColumn: JSX.Element;
  imageColumn: JSX.Element;
}) => {
  return (
    <>
      <Box
        display="flex"
        flex="1"
        flexDirection={['column-reverse', 'column-reverse', 'row', 'row']}
      >
        <Box
          className="leftColumn"
          display="flex"
          flexDirection={['row', 'row', 'column', 'column']}
          justifyContent="center"
          flex={['none', 'none', '1', '1']}
        >
          <Box display="flex" justifyContent="end">
            {leftColumn}
          </Box>
        </Box>
        <Box
          className="rightColumn"
          display="flex"
          flexDirection="column"
          minWidth="60vw"
          flex={['1', '1', 'none', 'none']}
        >
          <Box
            flexBasis="0px"
            flexGrow={1}
            overflowY={['visible', 'visible', 'auto', 'auto']}
          >
            {imageColumn}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export { JumbotronCarrouselHomePage };
