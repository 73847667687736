import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import { IHeroWithPillarLinksProps } from '../HeroWithPillarLinks';

const TopIcon = ({ props }: { props: IHeroWithPillarLinksProps }) => {
  return (
    <>
      {props.icon && (
        <Box display={['none', 'none', 'none', 'block']}>
          <Image src={props.icon} />
        </Box>
      )}
    </>
  );
};

export { TopIcon };
