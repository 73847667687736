import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import { IPillar } from '../HeroWithPillarLinks';
import { Text } from '@irishlifedigitalhub/ds.atoms.text';
import { Button } from '@irishlifedigitalhub/ds.atoms.button';
import { ArrowRightCircleIcon } from '@irishlife/ilgroupdesignsystem.icons';
import { fireAnalyticsEvent } from 'common/utils/fireAnalyticsEvent';
import { navigate } from '@reach/router';
import { useDesktop } from 'common/hooks/useDesktop';

const Pillar = ({ pillar }: { pillar: IPillar }) => {
  const desktop = useDesktop();

  return (
    <Box
      key={pillar.codename}
      display="flex"
      flexDirection="column"
      flex="calc(50% - 8px)"
      width="100%"
      paddingTop="24px"
      paddingRight="16px"
      paddingBottom="24px"
      paddingLeft="16px"
      gap="16px"
      borderRadius="12px"
      borderTop="7px"
      borderTopStyle="solid"
      borderColor={
        pillar.top_border_color ? pillar.top_border_color : '#5261ac'
      }
      backgroundColor={
        pillar.background_color ? pillar.background_color : '#ffffff'
      }
    >
      <Box display="flex" flexDirection="column" width="100%" gap="8px">
        {pillar.icon && (
          <Box display="flex" width="100%" justifyContent="center">
            <Image src={pillar.icon} />
          </Box>
        )}
        <Text variant="title-xs" color="#0C1E68" textAlign="center">
          {desktop && pillar.title}
          {!desktop && pillar.title_mobile && pillar.title_mobile}
          {!desktop && !pillar.title_mobile && pillar.title}
        </Text>
      </Box>
      <Box display="flex" gap="16px" justifyContent="center">
        <Button
          width={['100%', '100%', '100%', 'auto']}
          rightIcon={
            desktop ? <ArrowRightCircleIcon w="28px" h="28px" /> : undefined
          }
          backgroundColor={'var(--Vibrant-700, #5E6CDA)'}
          onClick={() => {
            fireAnalyticsEvent({
              name: 'secondaryCtaClick',
              properties: {
                clickText: pillar.cta_label,
                clickURL: pillar.cta_url,
                clickID: pillar.cta_analytics_id ? pillar.cta_analytics_id : '',
              },
            });
            navigate(pillar.cta_url);
          }}
        >
          {pillar.cta_label}
        </Button>
      </Box>
    </Box>
  );
};

export { Pillar };
