import React from 'react';
import { JumbotronInfo } from '../JumbotronCarrouselHomePage';
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text';

const Title = ({ jumbotronDetails }: { jumbotronDetails: JumbotronInfo }) => {
  return (
    <>
      <Text
        variant={['title-md', 'title-md', 'title-md', 'title-xl']}
        color={['white', 'white', 'white', 'white']}
        // my={['12px', '12px', '12px', '24px']}
      >
        {jumbotronDetails.title}
      </Text>
    </>
  );
};

export { Title };
