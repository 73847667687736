import { Box } from '@chakra-ui/react';
import styled from 'styled-components';

const StepperBox = styled(Box)`
  & label {
    color: #4d599e;

    & > span {
      color: #4d599e;
    }
  }

  & img {
    cursor: pointer;
  }

  & [data-testid='step-item'] {
    --chakra-colors-vibrant-500: #5463d6;
  }
`;

const StepperWrapper = styled(Box)`
  display: flex;

  & > div {
    display: flex;
  }
`;

export { StepperBox, StepperWrapper };
