import { IconButton } from "@chakra-ui/react";
import styled from "styled-components";

const IconButtonPlayPause = styled(IconButton)`
  & img {
    max-width: 16px;
    max-height: 16px;
  }

  :hover {
    & img {
      filter: brightness(0) invert(1);
    }
  }
`;

export { IconButtonPlayPause }