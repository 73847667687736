import React from 'react';
import { JumbotronInfo } from '../JumbotronCarrouselHomePage';
import { Box } from '@chakra-ui/react';
import { BasicButton } from 'common/components/atoms/BasicButton';
import {
  getJumbotronCallToActionData,
  getJumbotronSecondaryCallToActionData,
} from '../../../Jumbotron/getData';
import * as S from '../../HeroWithCarrousel.styles';

const CTAs = ({
  jumbotronDetails,
  jumbotronIndex,
}: {
  jumbotronDetails: JumbotronInfo;
  jumbotronIndex: number;
}) => {
  const callToAction = getJumbotronCallToActionData(jumbotronDetails);
  const SecondaryCallToAction =
    getJumbotronSecondaryCallToActionData(jumbotronDetails);
  const isCallToAction: boolean =
    callToAction && callToAction.label ? true : false;
  const isSecondaryCallToAction: boolean =
    SecondaryCallToAction && SecondaryCallToAction.label ? true : false;

  return (
    <>
      {(isCallToAction || isSecondaryCallToAction) && (
        <Box
          display="flex"
          gap="16px"
          flexDirection={['column', 'column', 'row', 'row']}
        >
          {isCallToAction && (
            <S.PrimaryCTA>
              <BasicButton
                {...callToAction}
                analyticsEventName={
                  jumbotronDetails.analyticseventname_primary
                    ? jumbotronDetails.analyticseventname_primary
                    : undefined
                }
                label={callToAction?.label}
                variant="bright"
                key={`primary-button-jumbotron-${jumbotronIndex}`}
              />
            </S.PrimaryCTA>
          )}

          {isSecondaryCallToAction && (
            <S.SecondaryCallToActionBox display="flex">
              <BasicButton
                analyticsEventName={
                  jumbotronDetails.analyticseventname_secondary
                    ? jumbotronDetails.analyticseventname_secondary
                    : undefined
                }
                {...SecondaryCallToAction}
                label={SecondaryCallToAction?.label}
                key={`secondary-button-jumbotron-${jumbotronIndex}`}
              />
            </S.SecondaryCallToActionBox>
          )}
        </Box>
      )}
    </>
  );
};

export { CTAs };
