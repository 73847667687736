import React from 'react';
import { JumbotronInfo } from '../JumbotronCarrouselHomePage';
import { Box, Image } from '@chakra-ui/react';
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text';

const AppsButtonsAndHyperlink = ({
  jumbotronDetails,
}: {
  jumbotronDetails: JumbotronInfo;
}) => {
  const isAppButton1: boolean =
    jumbotronDetails.app_button_1___image && jumbotronDetails.app_button_1___url
      ? true
      : false;

  const isAppButton2: boolean =
    jumbotronDetails.app_button_2___image && jumbotronDetails.app_button_2___url
      ? true
      : false;

  const isHyperlinkText: boolean =
    jumbotronDetails.hyperlink___text && jumbotronDetails.hyperlink___url
      ? true
      : false;

  return (
    <>
      {(isAppButton1 || isAppButton2 || isHyperlinkText) && (
        <Box display="flex" flexDirection="column" gap="12px">
          {(isAppButton1 || isAppButton2) && (
            <Box display="flex" gap="16px">
              {isAppButton1 && (
                <a
                  href={jumbotronDetails.app_button_1___url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={jumbotronDetails.app_button_1___image} />
                </a>
              )}

              {isAppButton2 && (
                <a
                  href={jumbotronDetails.app_button_2___url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={jumbotronDetails.app_button_2___image} />
                </a>
              )}
            </Box>
          )}

          {isHyperlinkText && (
            <Box display="flex">
              <a href={jumbotronDetails.hyperlink___url}>
                <Text color="#ffffff" textDecorationLine="underline">
                  {jumbotronDetails.hyperlink___text}
                </Text>
              </a>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export { AppsButtonsAndHyperlink };
