import React from 'react';
import { JumbotronInfo } from '../JumbotronCarrouselHomePage';
import { Box } from '@chakra-ui/react';
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text';

const BodyOfText = ({
  jumbotronDetails,
}: {
  jumbotronDetails: JumbotronInfo;
}) => {
  return (
    <>
      {jumbotronDetails.body_of_text && (
        <Box display="flex" flexDirection="column">
          <Text variant="subtitle-md" color="white" lineHeight="23px">
            {jumbotronDetails.body_of_text}
          </Text>
        </Box>
      )}
    </>
  );
};
export { BodyOfText };
