import React from 'react';
import { JumbotronInfo } from '../JumbotronCarrouselHomePage';
import { Box } from '@chakra-ui/react';
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text';

const Subtitle = ({
  jumbotronDetails,
}: {
  jumbotronDetails: JumbotronInfo;
}) => {
  return (
    <>
      {jumbotronDetails.subtitle && (
        <Box>
          <Text
            variant="caption-xl"
            color="var(--colour-background-page, #F7F8FB)"
          >
            {jumbotronDetails.subtitle}
          </Text>
        </Box>
      )}
    </>
  );
};
export { Subtitle };
