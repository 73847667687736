import { Box } from '@material-ui/core'
import { Text } from 'common/components/molecules/Text'
import { useDesktop } from 'common/hooks/useDesktop'
import { fireAnalyticsEvent } from 'common/utils/fireAnalyticsEvent'
import React from 'react'

export interface JumbotronContactProps {
    contactTitle?: string
    contactText?: string
    contactIcon?: string
}

export const JumbotronContact: React.FC<JumbotronContactProps> = ({
    ...props
}) => {
    const desktop = useDesktop()
    const { contactTitle, contactText, contactIcon } = props

    return (
        <Box
            display={'flex'}
            flexWrap={'wrap'}
            marginTop={!desktop ? 4 : 6}
            marginBottom={!desktop ? 4 : 6}
            width={'75%'}
        >
            <Box flexGrow={1}>
                {contactIcon && (
                    <img
                        style={{
                            width: '28px',
                            height: '28px',
                            maxWidth: '56px',
                            marginRight: '5%'
                        }}
                        alt='icon'
                        src={contactIcon}
                    />
                )}
            </Box>
            <Box flexGrow={2}>
                {typeof contactTitle === 'string' && (
                    <a
                        href={`tel:${contactTitle}`}
                        onClick={() =>
                            fireAnalyticsEvent({
                                name: 'primaryButtonClick',
                                properties: {
                                    clickText: props.contactText || ''
                                }
                            })
                        }
                    >
                        <Text
                            box={{ color: '#4D599E' }}
                            label={contactTitle}
                            variant='h6'
                        />
                    </a>
                )}
            </Box>
            <Box flexGrow={1} width={'12em'} mt={!desktop ? 4 : 2}>
                {typeof contactText === 'string' && (
                    <Text label={contactText} variant='caption' />
                )}
            </Box>
        </Box>
    )
}
