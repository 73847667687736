import React from 'react';
import { JumbotronInfo } from '../JumbotronCarrouselHomePage';
import { Box, Image } from '@chakra-ui/react';
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text';

const Awards = ({ jumbotronDetails }: { jumbotronDetails: JumbotronInfo }) => {
  return (
    <>
      {(jumbotronDetails.awards_title ||
        jumbotronDetails.award_image___1 ||
        jumbotronDetails.award_image___2 ||
        jumbotronDetails.award_image___3) && (
        <Box
          display={
            jumbotronDetails.awards_hide_on_mobile &&
            jumbotronDetails.awards_hide_on_mobile === 'Yes'
              ? ['none', 'none', 'flex', 'flex']
              : ['flex', 'flex', 'flex', 'flex']
          }
          flexDirection="column"
          gap="8px"
        >
          {jumbotronDetails.awards_title && (
            <Text variant="caption-xl" color="var(--Brand-50, #F7F8FB)">
              {jumbotronDetails.awards_title}
            </Text>
          )}
          {(jumbotronDetails.award_image___1 ||
            jumbotronDetails.award_image___2 ||
            jumbotronDetails.award_image___3) && (
            <Box display="flex" gap="12px">
              {jumbotronDetails.award_image___1 && (
                <Image src={jumbotronDetails.award_image___1} />
              )}
              {jumbotronDetails.award_image___2 && (
                <Image src={jumbotronDetails.award_image___2} />
              )}
              {jumbotronDetails.award_image___3 && (
                <Image src={jumbotronDetails.award_image___3} />
              )}
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export { Awards };
