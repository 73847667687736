import { useCallback, useEffect, useRef, useState } from 'react';

interface Props {
  length: number;
  transition_speed: number;
  isEnabledSlideShow: boolean;
}

const useSlideShow = (props: Props) => {
  const [index, setIndex] = useState(0);

  const intervalSeconds =
    props.transition_speed === 0 ? 6000 : props.transition_speed * 1000;
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const [autoSlideshow, setAutoSlideshow] = useState<boolean>(true);

  const nextSlide = () => {
    setIndex((prevIndex) => (prevIndex + 1) % props.length);
    resetInterval();
  };

  const prevSlide = () => {
    setIndex((prevIndex) =>
      prevIndex === 0 ? props.length - 1 : prevIndex - 1
    );
    resetInterval();
  };

  const goToSlide = (slideIndex: number) => {
    setIndex(slideIndex);
    resetInterval();
  };

  const toggleAutoSlideshow = () => {
    if (autoSlideshow) {
      setAutoSlideshow(false);
    }

    if (!autoSlideshow) {
      setAutoSlideshow(true);
    }

    return;
  };

  const resetInterval = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    if (autoSlideshow) {
      intervalRef.current = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % props.length);
      }, intervalSeconds);
    }
  }, [props.length, intervalSeconds, autoSlideshow]);

  useEffect(() => {
    if (props.isEnabledSlideShow) {
      resetInterval();

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }
  }, [props.isEnabledSlideShow, resetInterval]);

  return {
    index,
    goToSlide,
    prevSlide,
    nextSlide,
    autoSlideshow,
    toggleAutoSlideshow,
  };
};

export { useSlideShow };
