import { ShardCard } from '@irishlifedigitalhub/ds.molecules.shard-card';
import React from 'react';
import { Text } from '@irishlifedigitalhub/ds.atoms.text';
import { IShardCard } from '../HeroWithPillarLinks';
import { getJumbotronCallToActionData } from '../../Jumbotron';
import { Button } from '@irishlifedigitalhub/ds.atoms.button';
import { Box } from '@chakra-ui/react';
import { fireAnalyticsEvent } from 'common/utils/fireAnalyticsEvent';
import { navigate } from '@reach/router';
import { useDesktop } from 'common/hooks/useDesktop';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  img {
    transition-property: var(--chakra-transition-property-common);
    transition-duration: var(--chakra-transition-duration-normal);
  }
  :hover {
    img {
      filter: brightness(0) invert(1) !important;
    }
  }
`;

const ShardCardCarrousel = ({
  shardCard,
  stepper,
}: {
  shardCard: IShardCard;
  stepper: JSX.Element;
}) => {
  const desktop = useDesktop();
  let callToAction: any;

  if (
    shardCard.templatesection___shardcard__cta &&
    shardCard.templatesection___shardcard__cta.length > 0
  ) {
    callToAction = getJumbotronCallToActionData(
      shardCard.templatesection___shardcard__cta[0]
    );
  }

  const fakeColor = {
    default:
      'invert(43%) sepia(91%) saturate(2132%) hue-rotate(216deg) brightness(88%) contrast(92%)',
    hover:
      'invert(100%) sepia(0%) saturate(2%) hue-rotate(338deg) brightness(106%) contrast(101%)',
  };

  return (
    <ShardCard
      callback={() => {}}
      noCallback
      imageSrc={shardCard.templatesection___shardcard__image}
    >
      <Box
        display="flex"
        width="100%"
        height="100%"
        flexDirection="column"
        gap="36px"
      >
        <Box display="flex" flexDirection="column" gap="12px" overflow="hidden">
          {shardCard.templatesection___shardcard__title && (
            <Text
              color={['brand.800', 'brand.800', 'brand.800', 'brand.800']}
              variant={['title-sm', 'title-sm', 'title-sm', 'title-lg']}
            >
              {shardCard.templatesection___shardcard__title}
            </Text>
          )}

          {shardCard.templatesection___shardcard__text && (
            <Text
              variant={'body-md'}
              color={'Monochrome.800'}
              marginTop={'0px !important'}
            >
              {shardCard.templatesection___shardcard__text}
            </Text>
          )}
        </Box>

        {callToAction && (
          <Box>
            <StyledButton
              variant={callToAction.variant}
              size={desktop ? 'md' : 'sm'}
              onClick={() => {
                fireAnalyticsEvent({
                  name: 'secondaryCtaClick',
                  properties: {
                    clickText: callToAction.label,
                    clickURL: callToAction.url,
                    clickID: callToAction.analyticsId,
                  },
                });
                navigate(callToAction.url);
              }}
            >
              {callToAction.label}
              {callToAction.endIcon &&
                <img
                  alt="Right Icon"
                  src={callToAction.endIcon}
                  style={{
                    filter:
                      callToAction.variant === 'secondary'
                        ? fakeColor['default']
                        : undefined,
                    marginLeft: '8px',
                    marginRight: '-8px',
                    width: desktop ? '28px' : '16px',
                    height: desktop ? '28px' : '16px',
                  }}
                />
              }
            </StyledButton>
          </Box>
        )}

        {stepper}
      </Box>
    </ShardCard>
  );
};

export { ShardCardCarrousel };
